<template>
    <div class="concept-section" v-if="conceptArr != null" id="menu-link-concept">
        <div class="section-title">
            <h2>{{conceptArr.concept_title_name}}</h2>
        </div>
        <div class="concept-section-content">
            <conceptArch></conceptArch>
            <div class="concept-section-content-info-container">
                <conceptSafe></conceptSafe>
                <conceptDetails></conceptDetails>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import conceptArch from '@/components/concept/concept-arch.vue'
    import conceptSafe from '@/components/concept/concept-safe.vue'
    import conceptDetails from '@/components/concept/concept-details.vue'
    export default {
        name: 'concept-section',
        data () {
            return {
            }
        },
        computed: mapGetters({
            conceptArr: 'loadConcept',
        }),
        components: {
            conceptArch,
            conceptSafe,
            conceptDetails,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/concept/concept-section.scss';
    @import '@/styles/components/concept/respond/concept-section-respond.scss';
</style>