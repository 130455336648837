<template>
    <div class="home">
        <indexPromoSection></indexPromoSection>
        <premsSection></premsSection>
        <formSection class="form-section-top" v-if="formSetUp != null && formSetUp.form_location_init == 'true'"></formSection>
        <locationSection></locationSection>
        <formSection class="form-section-top" v-if="formSetUp != null && formSetUp.form_concept_init == 'true'"></formSection>
        <conceptSection :class="{'concept-section-with-form' : formSetUp != null && formSetUp.form_concept_init == 'true'}"></conceptSection>
        <formSection class="form-section-top" v-if="formSetUp != null && formSetUp.form_innersection_init == 'true'"></formSection>
        <innerSection :class="{'inner-section-with-form' : formSetUp != null && formSetUp.form_innersection_init == 'true'}"></innerSection>
        <lobbiSection></lobbiSection>
        <formSection class="form-section-top"></formSection>
        <flatsSection></flatsSection>
        <formSection class="form-section-bottom"></formSection>
        <gallerySection></gallerySection>
        <!-- <formSection></formSection> -->
        <newsSection></newsSection>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import indexPromoSection from '@/components/index-promo/index-promo.vue'
import premsSection from '@/components/prems/prems-section.vue'
import locationSection from '@/components/location/location-section.vue'
import conceptSection from '@/components/concept/concept-section.vue'
import innerSection from '@/components/inner-content/inner-section.vue'
import lobbiSection from '@/components/lobbi/lobbi-section.vue'
import flatsSection from '@/components/flats/flats-section.vue'
import gallerySection from '@/components/gallery/gallery-section.vue'
import newsSection from '@/components/news/news.vue'
import formSection from '@/components/forms/form-section.vue'

export default {
    name: 'HomeView',
    components: {
        indexPromoSection,
        premsSection,
        locationSection,
        conceptSection,
        innerSection,
        lobbiSection,
        flatsSection,
        gallerySection,
        newsSection,
        formSection,
    },
    methods: {
    },
    computed: mapGetters({
        formSetUp: 'loadFormSetUp',
    }),
}
</script>

<style lang="scss">
    @import '@/styles/components/home.scss';
</style>

