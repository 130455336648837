<template>
    <div class="location-section" v-if="locationArr != null" id="menu-link-location">
        <div class="section-title">
            <h2>{{locationArr.location_title_name}}</h2>
        </div>
        <div class="location-section-content">
            <locationMap></locationMap>
            <locationCollection></locationCollection>
            <locationPanarams></locationPanarams>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    import locationMap from '@/components/location/location-map.vue'
    import locationCollection from '@/components/location/location-collection.vue'
    import locationPanarams from '@/components/location/location-panarams.vue'

    export default {
        name: 'location-section',
        data () {
            return {
            }
        },
        computed: mapGetters({
            locationArr: 'loadLocation',
        }),
        components: {
            locationMap,
            locationCollection,
            locationPanarams,
            // svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/location/location-section.scss';
    @import '@/styles/components/location/respond/location-section-respond.scss';
</style>