<template>
    <div class="flats-crm-items-container flats-items-list" v-if="flatsItems != null">
        <div class="flats-empty-msg" v-if="flatsEmptyMsg"><p>По данному запросу квартир в продаже не найдено</p></div>
        <div class="flat-cards-items-list-info" v-if="!flatsEmptyMsg && !mobileView">
            <ul>
                <li>
                    <p>
                        <span v-if="globalLang != 'kz/'">Планировка</span>
                        <span v-else>Планировка</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span v-if="globalLang != 'kz/'">Комнат</span>
                        <span v-else>Бөлмелер</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span v-if="globalLang != 'kz/'">Площадь</span>
                        <span v-else>Ауданы</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span v-if="globalLang != 'kz/'">Этаж</span>
                        <span v-else>Қабат</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span v-if="globalLang != 'kz/'">Апартаменты №</span>
                        <span v-else>Пәтерлер №</span>
                    </p>
                </li>
            </ul>
        </div>
        <div class="flats-items-list-loop" v-if="!flatsEmptyMsg">
            <div class="flat-item" v-for="item in flatsItems" :key="item.id">
                <a href="#" @click.prevent="openCrmFlatModal(item)">
                    <div class="item-default item-img" v-if="item != null && item.id != null && item.images.length > 0">
                        <div class="img-in">
                            <img :src="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pic.id" v-for="(pic, index) in item.images.filter(item => item.typeCode === '1')" :key="index">
                        </div>
                        <!-- <div class="card-table-list-img">
                            <img :src="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pic.id" v-for="(pic, index) in item.images.filter(item => item.typeCode === '1')" :key="index">
                        </div> -->
                    </div>
                    <div class="item-info">
                        <ul>
                            <li>
                                <p v-if="mobileView">
                                    <span v-if="globalLang != 'kz/'">Комнат</span>
                                    <span v-else>Бөлмелер</span>
                                </p>
                                <strong>{{item.rooms}}</strong>
                            </li>
                            <li>
                                <p v-if="mobileView">
                                    <span v-if="globalLang != 'kz/'">Площадь</span>
                                    <span v-else>Ауданы</span>
                                </p>
                                <strong>{{item.area}} <span>м²</span></strong>
                            </li>
                            <li>
                                <p v-if="mobileView">
                                    <span v-if="globalLang != 'kz/'">Этаж</span>
                                    <span v-else>Қабат</span>
                                </p>
                                <strong>{{item.floor}}</strong>
                                
                            </li>
                            <li>
                                <p v-if="mobileView">
                                    <span v-if="globalLang != 'kz/'">Апартаменты №</span>
                                    <span v-else>Пәтерлер №</span>
                                </p>
                                <strong>{{item.number}}</strong>
                            </li>
                        </ul>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'flats-items-list',
        props: ['mobileView'],
        components: {
        },
        data () {
            return {
                globalLang: window.GlobalLang, 
            }
        },
        methods: {
            getNumbersOfString(str) {
                return str.replace(/[^0-9]+/g, "");
            },
            getNumbersOfBlock(str) { 
                var parseStr = str.split(',')[0];
                return parseStr.replace(/[^0-9]+/g, "");
            },
            getNumber(n) {
                return String(n).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            },
            openCrmFlatModal(arr) {
                this.$store.commit('modalData', arr);
                setTimeout(() => {
                    this.$vfm.show('flats-crm-modal')
                }, 300);
            },
            getPriceForm() {

            },
            startLoader() {
                this.$parent.$refs.topProgress.start();
            },
            openFormModal(param) {
                this.$store.commit('Open_Form_Modal', param);
            },
        },
        computed: mapGetters({
            flatsItems: 'loadFlatsItems',
            flatsEmptyMsg: 'loadFlatsEmptyMsg',
        }),
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/flats-crm-items-list.scss';
    @import '@/styles/components/flats-crm/flats-crm-items-main.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-items-list-respond.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-items-main-respond.scss';
    // @import '@/styles/components/flats-page/respond/flats-item-respond.scss';
</style>