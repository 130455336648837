<template>
    <div class="location-map">
        <div class="container">
            <div class="location-map-info">
                <div class="section-title-default">
                    <h3 v-html="locationArr.location_title"></h3>
                    <svgIcon name="arrow-title" />
                </div>
                <div class="desc-columns">
                    <div class="col-left">
                        <div class="desc-col"><p v-html="locationArr.location_desc"></p></div>
                    </div>
                    <div class="col-right">
                        <div class="desc-col"><p v-html="locationArr.location_desc_right"></p></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="location-map-container">
            <div class="location-bg" :style="'background-image:url(' + mapBg + ')'"></div>
            <div class="location-map-img" id="scrollContainerMapLocation">
                <img :src="locationArr.location_map" alt="" v-if="globalLang != 'kz/'">
                <img :src="locationArr.location_map_kz" alt="" v-else>
            </div>
            <div class="link-location-panoram">
                <a href="#" @click.prevent="openPanorama = true">{{ locationArr.location_panorama_btn }}</a>
            </div>
            <Transition name="fade">
                <div class="location-panoram-img" v-if="openPanorama">
                    <img :src="locationArr.location_panorama" alt="">
                    <div class="btn-close">
                        <a href="#" @click.prevent="openPanorama = false"><svgIcon name="close" class="icon-close"/></a>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'location-map',
        data () {
            return {
                globalLang: window.GlobalLang, 
                openPanorama: false,
                mapBg: require('@/assets/images/samples/location-bg-01.png'),
                sectionBg: require('@/assets/images/samples/location-bg-02.png'),
            }
        },
        computed: mapGetters({
            locationArr: 'loadLocation',
        }),
        components: {
            svgIcon,
            // promoForm,
        },
        methods: {
            scrollLeftMapMobile() {
                setTimeout(() => {
                    document.getElementById('scrollContainerMapLocation').scrollLeft += 150;
                }, 300);
            }
        },
        mounted() {
            this.scrollLeftMapMobile();
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/location/location-map.scss';
    @import '@/styles/components/location/respond/location-map-respond.scss';
</style>