<template>
    <div class="gallery-section" v-if="galleryArr != null" id="menu-link-gallery">
        <div class="container">
            <div class="section-title section-title-small title-gallery">
                <h2>{{galleryArr.section}}</h2>
            </div>
            <div class="swiper-controls gallery-controls">
                <div id="galPref" class="gal-prev-slide slider-btn">
                    <svgIcon name="arrow-left" />
                </div>
                <div id="galNext" class="gal-next-slide slider-btn">
                    <svgIcon name="arrow-right" />
                </div>
            </div>
        </div>
        <div class="gallery-carousel">
            <swiper 
                :modules="modules" 
                :navigation="swiperOptions.navigation"
                slides-per-view="1"
                effect="fade"
                :space-between="0"
                :speed="1000"
                :autoplay="swiperOptions.autoplay"
                :observer="true"
                :observe-parents="true"
                :simulate-touch="true"
                :loop="true"
            >
                <swiper-slide v-for="(item, index) in galleryArr.gallery" :key="index">
                    <div class="slide-pic" :style="'background-image: url(' + item + ')'"></div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'

    // import svgIcon from '@/components/partials/SvgIcon.vue';
    import { EffectFade, Autoplay, Navigation} from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	// Import Swiper styles
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';

    export default {
        name: 'gallery-section',
        data () {
            return {
                // lobbiPin: require('@/assets/images/evo-pin_01.svg'),
                swiperOptions: {
                    navigation: {
                        nextEl: '#galNext',
                        prevEl: '#galPref'
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: true
                    },
                },
            }
        },
        computed: mapGetters({
            galleryArr: 'loadGalleryArr',
        }),
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        setup() {
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation],
			};
		},
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/gallery/gallery-section.scss';
    @import '@/styles/components/gallery/gallery-section-respond.scss';
    // @import '@/styles/components/location/respond/location-section-respond.scss';
</style>