<template>
    <div class="form-section" v-if="formSetUp != null">
        <div class="column-container">
            <div class="col-left">
                <div class="form-titles">
                    <h3>{{formSetUp.form_title}}</h3>
                    <span>{{formSetUp.form_title_desc}}</span>
                </div>
            </div>
            <div class="col-right">
                <formFields></formFields>
            </div>
            <div class="privacy-desc">
                <p>
                    <span>{{ formSetUp.form_privacy_desc.one }}</span> 
                    «<span>{{ formSetUp.btn_name }}</span>» <br>
                    <span class="two">{{ formSetUp.form_privacy_desc.two }}</span>
                    <span><a href="https://sales.bazis.kz/privacy-policy" target="_blank">{{formSetUp.form_privacy_desc.word }}</a></span>
                    <span class="three">{{ formSetUp.form_privacy_desc.three }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import formFields from '@/components/forms/form.vue'
    export default {
        name: 'form-section',
        data () {
            return {
            }
        },
        computed: mapGetters({
            formSetUp: 'loadFormSetUp',
        }),
        components: {
            formFields,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/forms/form-section.scss';
    @import '@/styles/components/forms/respond/form-section-respond.scss';
</style>