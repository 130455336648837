<template>
    <footer class="footer" id="footer" v-if="pageSetup != null">
        <div class="container">
            <div class="top-footer">
                <div class="col-left">
                    <div class="logos">
                        <div class="logo-main">
                            <a href="#wrapper-content" v-smooth-scroll><img alt="lumiere logo" :src="pageSetup.logo"></a>
                        </div>
                        <div class="logo-bazis">
                            <a :href="pageSetup.logo_bazis.link">
                                <img :src="pageSetup.logo_bazis.img" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="adress">
                        <svgIcon name="marker" />
                        <p v-html="pageSetup.project_sales_adres"></p>
                    </div>
                </div>
                <div class="col-right">
                    <div class="anounce">
                        <p>{{pageSetup.footer_zakon_desc}}</p>
                        <p>{{pageSetup.footer_zakon_desc_second}}</p>
                    </div>
                    <a href="https://sales.bazis.kz/privacy-policy" target="_blank" class="link-to-policy">
                        <span v-if="globalLang == ''">Политика конфиденциальности</span>
                        <span v-else>Құпиялылығы саясаты</span>
                    </a>
                </div>
            </div>
            <div class="copy">
                <p>{{pageSetup.copyright}}</p>
            </div>
            <div class="developed-team">
                <div class="made-in">
                    Developed by 
                    <a href="https://v-web.kz" target="_blank">{{pageSetup.victo_name}}</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import {mapGetters} from 'vuex'
    export default {
        name: 'AppFooter',
        data () {
            return {
                globalLang: window.GlobalLang, 
            }
        },
        computed: mapGetters({
            // openSendwich: 'loadOpenSendwich',
            pageSetup: 'loadPageSetUp',
        }),
        components: {
            svgIcon,
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/footer/footer.scss';
    @import '@/styles/components/footer/footer-respond.scss';
    // @import '@/styles/components/footer/footer-respond.scss';
</style>