<template>
   <div class="section section-news" id="menu-link-news" v-if="newsPage != null">
        <div class="section-title section-title-small title-news">
                <h2>{{newsPage.news_title}}</h2>
            </div>
        <div class="news-section-content">
            <div class="container">
                <!-- <div class="section-title-page">
                    <h2>{{newsPage.title}}</h2>
                </div> -->

                <vue-modal name="news-modal" 
                    classes="news-modal-container" 
                    content-class="news-modal-content" 
                    v-model="showNewsModal" 
                    id="#news_modal" 
                    @closed="NewsModalClosed"  
                    @before-open="NewsModalBeOpened">
                    <newsModal :config="[{loading: loading, notVisible: notVisible, visible: visible}]"></newsModal>
                </vue-modal>

                <div class="news-items" >
                    <div class="news-item" v-for="(item, index) in newsPage.news_items" :key="index">
                        <div class="news-circle">
                            <div class="circle-wrapper">
                                <div class="circle-item">
                                    <svg viewBox="0 0 36 36" class="circular-chart">
                                        <path class="circle-bg"
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <path class="circle"
                                            :stroke-dasharray="item.ready_number + ',100'"
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                    </svg>
                                </div>
                                <div class="number-activity" :data-ready="item.ready_number">
                                    <h4>{{item.ready_number}}%</h4>
                                </div>
                            </div>
                            <div class="news-circle-bottom-controls">
                                <div class="news-circle-title">
                                    <h3>{{ item.title }}</h3>
                                </div>
                                <div class="btn-open-news-info-modal">
                                    <a href="#" class="btn btn-open-news-modal"  :class="{disabled : item.btn_activity == false}"
                                        @click.prevent="openNewsModalInit(index)"
                                        >
                                    <span>{{ newsPage.news_btn_more }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
   </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import newsModal from '@/components/news/news-modal.vue'
    export default {
        name: 'news-section',
        components: {
            newsModal,
        },
        data () {
            return {
                // lang: window.GlobalLang,
                get: [
                    {
                        link: 'lumiere_one'
                    },
                    {
                        link: 'lumiere_two'
                    },
                    {
                        link: 'lumiere_three'
                    },
                ],
                ready: [
                    {
                        number: 0
                    },
                ],
                valueOption: 0,
                visible: false,
                visibleCarousel: false,
                activity: [],
                loading: true,
                notVisible: true,
                animate: false,
                modalWidth: 1000,
                modalHeight: 'auto',
                modalScrollable: false,
                transitionName: 'pop-out',

                showNewsModal: false,
                swiperOption: {
                    speed: 600,
                    observer: true,
                    observeParents: true,
                    spaceBetween: 20,
                    effect: 'slide',
                    navigation: {
                        nextEl: '.news-btn-next ',
                        prevEl: '.news-btn-prev',
                    }
                },
                intersectionOptions: {
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0.25,0.75]
                }
            }
        },
        computed: mapGetters({
            newsPage: 'loadNews',
            isMobileGlobal: 'loadisMobileGlobal',
        }),
        methods: {
            // onWaypoint ({ el, going, direction }) {
            //     if (this.$waypointMap.GOING_IN === going) {
            //         el.classList.add('animated-active');
            //         this.animate = true;
            //         this.setReadyNumber();
            //     }
            // },
            formatter(num) {
                return num.toFixed();
            },
            setReadyNumber() {
                var i;
                setTimeout(()=>{
                    for (i=0; i < this.newsPage.news_items.length; i++) {
                        this.ready[i].number = this.newsPage.news_items[i].ready_number
                    }
                },300);
            },
            checkActivity () {
                var i;
                for (i = 0; i < this.newsPage.news_items.length; i++) {
                    this.activity.push(this.newsPage.news_items[i].btn_activity);
                }
                // console.log(this.activity);
            },  
            openNewsModalInit(index) {
                var _self = this;
                this.$store.dispatch('clearNewsData');
                axios.get(window.newsUrl + window.GlobalLang + window.JsonUrl + this.get[index].link)
                .then((response) => {
                    if(response)
                    // console.log(response)
                    for (var i = 0; i < response.data.length; i++) {
                        this.$store.commit('newsData', response.data[i].acf);
                    }
                    _self.notVisible = false;
                    _self.loading = false;
                    _self.visible = true;
                    // setTimeout(function(){
                    //     _self.notVisible = false;
                    //     _self.loading = false;
                    // },800);
                    // this.visible = true;
                });
                this.$vfm.show('news-modal')
                // document.body.classList.add('overlay-open');
                // document.getElementById('secret-btns').classList.remove('fadeIn');
            },
            beforeNewsModalClose() {
                this.notVisible = true;
            },
            NewsModalClosed() {
                this.valueOption = 0;
                this.visible = false;
                this.visibleCarousel = false;
                this.$store.dispatch('clearNewsData');
                document.getElementById('header').classList.remove('notVisible');
                document.getElementById('footer').classList.remove('notVisible');
                if (!this.isMobileGlobal) {
                    document.getElementById('header-fixed').classList.remove('notVisible');
                }
			},
            NewsModalBeOpened() {
                document.getElementById('header').classList.add('notVisible');
                document.getElementById('footer').classList.add('notVisible');
                if (!this.isMobileGlobal) {
                    document.getElementById('header-fixed').classList.add('notVisible');;
                }
            },
            // newsModalClosed() {
            //     document.body.classList.remove('overlay-open');
            //     document.getElementById('secret-btns').classList.add('fadeIn');
            // },
            settingsNewsModal() {
				var _self = this;
				if ($(window).width() > 1024) {
					_self.modalWidth = 1000;
					_self.modalHeight = 'auto';
					_self.transitionName = 'pop-out';
				} else {
					_self.modalWidth = 100;
					_self.modalHeight = 'auto';
					_self.modalScroll = true;
					_self.transitionName = 'fade-enter';
				}
			},
			handleResize() {
                var _self = this;
                if (window.innerWidth >= 1024) {
                    _self.modalWidth = 1000;
					_self.modalHeight = 'auto';
                } else {
					_self.modalScroll = true;
					_self.modalHeight = 'auto';
				}
            },
            checkResize() {
                window.addEventListener('resize', this.handleResize)
                this.handleResize();
            },
        },
        mounted () {
            // this.$store.watch(
            //     (state) => state.news,
            //     (newValue) => {
            //         if (newValue)
            //         this.checkActivity();
            //         this.settingsNewsModal();
            //         this.checkResize();
            //     }
            // )
            // this.$store.watch(
            //     (state) => state.news,
            //     (newValue) => {
            //         if (newValue)
            //         this.checkActivity();
            //     }
            // )
        }
    } 
</script>

<style lang="scss" scoped>
    @import '@/styles/components/news/news.scss';
    @import '@/styles/components/news/respond/news-respond.scss';
    // @import '@/styles/components/news/news-modal-content.scss';
    // @import '@/styles/components/news/news-respond/news-respond.scss';
    // @import '@/styles/components/animation/animation-news.scss';
</style>