<template>
    <div class="location-panarams" id="menu-link-panorams">
        <div class="container">
            <div class="panarams-info">
                <div class="section-title-default">
                    <h3 v-html="locationArr.panorams_title"></h3>
                    <svgIcon name="arrow-title" />
                </div>
                <div class="desc-columns desc-columns-panorams">
                    <div class="col-left">
                        <div class="desc-col"><p v-html="locationArr.panorams_desc"></p></div>
                    </div>
                    <div class="col-right">
                        <div class="desc-col"><p v-html="locationArr.panorams_desc_right"></p></div>
                    </div>
                </div>
            </div>
            <div class="panarams-pre-view-container">
                <div class="panarams-carousel" :class="{'init' : openPanoramaModal}">
                    <swiper 
                        :modules="modules" 
                        :navigation="swiperOptions.navigation"
                        slides-per-view="1"
                        effect="fade"
                        :space-between="0"
                        :speed="1000"
                        :autoplay="swiperOptions.autoplay"
                        :observer="true"
                        :observe-parents="true"
                        :simulate-touch="true"
                        :loop="true"
                    >
                        <swiper-slide v-for="(item, index) in locationArr.panorams_images" :key="index">
                            <div class="slide-pic" :style="'background-image: url(' + item + ')'"></div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-controls panarams-controls" :class="{'visible' : openPanoramaModal}">
                        <div id="panaramsPref" class="panarams-prev-slide slider-btn">
                            <svgIcon name="arrow-left" />
                        </div>
                        <div id="panaramsNext" class="panarams-next-slide slider-btn">
                            <svgIcon name="arrow-right" />
                        </div>
                    </div>
                </div>
                
                <div class="link-location-panoram" v-if="!openPanoramaModal">
                    <a href="#" @click.prevent="openPanoramaModal = true">{{ locationArr.panorams_btn }}</a>
                </div>
            </div>
        </div>

        <!-- :autoplay="swiperOptions.autoplay" -->
        <!-- <div class="location-panarams-gallery">
            <div class="panarams-gallery">

                <swiper 
                    :modules="modules" 
                    :navigation="swiperOptions.navigation"
                    effect="fade"
                    :slides-per-view="1"
                    :space-between="0"
                    :observer="true"
                    :observe-parents="true"
                    :loop="true"
                >
                    <swiper-slide v-for="(item, index) in locationArr.section_panarams.items" :key="index">
                        <div class="slide-pic">
                            <img :src="item.img" alt="">
                        </div>
                        <div class="slide-content" v-if="item.desc != ''">
                            <div class="slide-desc">{{item.desc}}</div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="container container-controls">
                <div class="swiper-controls panarams-controls">
                    <div id="panaramsPref" class="panarams-prev-slide slider-btn">
                        <svgIcon name="arrow-left" />
                    </div>
                    <div id="panaramsNext" class="panarams-next-slide slider-btn">
                        <svgIcon name="arrow-right" />
                    </div>
                </div>
                <div class="swiper-pagination swiper-pagintaion-panarams" id="panaramsPagination"></div>
            </div>
            

        </div> -->
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import {mapGetters} from 'vuex'
    import { EffectFade, Autoplay, Navigation} from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	// Import Swiper styles
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';

    export default {
        name: 'location-panarams',
        data () {
            return {
                openPanoramaModal: false,
                swiperOptions: {
                    navigation: {
                        nextEl: '#panaramsNext',
                        prevEl: '#panaramsPref'
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: false
                    },
                },
            }
        },
        computed: mapGetters({
            locationArr: 'loadLocation',
        }),
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        setup() {
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation],
			};
		},
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/location/location-panarams.scss';
    @import '@/styles/components/location/respond/location-panarams-respond.scss';
</style>