<template>
    <!-- модалка планировки -->
    <!-- <modal name="sales_land_flat_card_crm_modal" @opened="FlatCardModalOpened" @closed="FlatCardModalClosed" :transition="transitionName" :width="modalWidth" :height="modalHeight" :scrollable="mobileView" id="sales_land_flat_card_crm_modal"> -->
        <!-- @opened="FlatCardModalOpened" -->
    <vue-modal name="flats-crm-modal" classes="flats-modal-container" content-class="modal-content" v-model="showFlatsCrmModal" id="#flat_card_crm_modal" @closed="FlatCardModalClosed"  @before-open="FlatCardModalBeOpened">
        <div class="modal-content land-flat-modal-content" v-if="modalData != null">
            <button class="btn-close-flat-card--modal" @click="closeFlatModal()">
                <svgIcon name="close" class="icon-close"/>
            </button>
            <h3 v-if="mobileView" class="mobile-modal-title"><strong>{{modalData.rooms}}-</strong>КОМНАТНЫЕ АПАРТАМЕНТЫ</h3>
            <div class="col-left" :class="{active: resizeCardImg}">
                <div class="btn-resize" :class="{active: resizeCardImg}" @click="resizeModalCardImg">
                    <svgIcon name="inresize" class="icon-inresize"/>
                    <svgIcon name="unresize" class="icon-unresize"/>
                </div>
                <div class="flat-card-big-img" v-if="modalData != null && modalData.id != null">
                    <div class="img-in" v-if="modalData.images.length > 0">
                        <img :src="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pic.id" v-for="(pic, index) in modalData.images.filter(item => item.typeCode === '1')" :key="index">
                    </div>
                </div>
                <div class="flat-card-info-images" v-if="modalData != null && modalData.id != null">
                    <div class="col" v-for="(pic, index) in modalData.images.filter(item => item.typeCode === '2')" :key="index"> 
                        <img :src="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pic.id">
                    </div>
                </div>
            </div>
            <div class="col-right" v-if="modalData != null">
                <div class="flat-card-top-info">
                    <h3 v-if="!mobileView"><strong>{{modalData.rooms}}-</strong>КОМНАТНЫЕ АПАРТАМЕНТЫ</h3>
                    <div class="item-info">
                        <ul>
                            <li>
                                <div class="left">
                                    <span v-if="globalLang != 'kz/'">Комнат</span>
                                    <span v-else>Бөлмелер</span>
                                </div>
                                <div class="right">
                                    {{modalData.rooms}}
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <span v-if="globalLang != 'kz/'">Площадь</span>
                                    <span v-else>Ауданы</span>
                                </div>
                                <div class="right">
                                    {{modalData.area}} <span>м²</span>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <span v-if="globalLang != 'kz/'">Этаж</span>
                                    <span v-else>Қабат</span>
                                </div>
                                <div class="right">
                                    {{modalData.floor}}
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <span v-if="globalLang != 'kz/'">Апартаменты №</span>
                                    <span v-else>Пәтерлер №</span>
                                </div>
                                <div class="right">
                                    {{modalData.number}}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flat-card-btns-container">
                    {{getIdPDF(modalData.images)}}
                    <div class="flat-btn-print">
                        <a :href="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pdf" download target="_blank" class="btn-print">
                            <svgIcon name="print" class="icon-print"/>
                            <p>{{salesCardPage.flats_cards_info.flat_btn_print_name}}</p>
                        </a>
                    </div>
                    <div class="flat-btn-mail">
                        <a href="#" class="btn-mail" @click.prevent="InputMailInit"  v-if="!formActive"> 
                            <svgIcon name="mail" class="icon-mail"/>
                            <p>{{salesCardPage.flats_cards_info.flat_btn_mail_send_name}}</p>
                        </a>
                        <formMail v-if="formActive"
                                :options="[{pdf: 'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pdf, name: modalData.rooms + ' ком. резиденция ' +  modalData.area + 'м²' + ' в LUMIERE.pdf'}]"
                        ></formMail>
                    </div>
                    <div class="flat-social-share-btns">
                        <div class="flats-social-share-title">
                            <h4>{{salesCardPage.flats_cards_info.flat_btn_social_title}}</h4>
                        </div>
                        <div class="flat-social-share-btns">
                            <button class="button btn-share-flat-to-social btn-share-flat-to-social-desctop" data-sharer="whatsapp" :data-title="'Ваша' + ' ' + modalData.rooms + '-' + salesCardPage.flats_cards_info.flat_modal_title + ' ' +  modalData.area + ' м²' + ' в ЖК LUMIERE'" :data-url="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pdf" data-web><svgIcon name="whatsapp-flat" class="flat-share-icon icon-whatsapp"/></button>
                            <button class="button btn-share-flat-to-social btn-share-flat-to-social-mobile" data-sharer="whatsapp" :data-title="'Ваша' + ' ' + modalData.rooms + '-' + salesCardPage.flats_cards_info.flat_modal_title + ' ' +  modalData.area + ' м²' + ' в ЖК LUMIERE'" :data-url="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pdf"><svgIcon name="whatsapp-flat" class="flat-share-icon icon-whatsapp"/></button>
                            <button class="button btn-share-flat-to-social" data-sharer="telegram" :data-title="'Ваша' + ' ' + modalData.rooms + '-' + salesCardPage.flats_cards_info.flat_modal_title + ' ' +  modalData.area + ' м²' + ' в ЖК LUMIERE'" :data-url="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pdf"><svgIcon name="telegram" class="flat-share-icon icon-telegram"/></button>
                            <!-- <button class="button btn-share-flat-to-social" data-sharer="facebook" :data-hashtag="'МояКвартира_в_LUMIERE'" :data-url="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pdf"><svgIcon name="facebook-flat" class="flat-share-icon icon-facebook"/></button> -->
                        </div>
                    </div>
                </div>
                <div class="flat-card-form flat-card-form-crm">
                    <div class="flat-card-form-titles">
                        <h3>{{formSetUp.flats_form_title}}</h3>
                        <p>{{formSetUp.flats_form_desc}}</p>
                    </div>
                    <formFields class="form-flats-card-setup"></formFields>
                </div>
                <div class="privacy-desc-mobile" v-if="formSetUp != null && isMobileGlobal">
                    <p>
                        <span>{{ formSetUp.form_privacy_desc.one }}</span> 
                        «<span>{{ formSetUp.btn_name }}</span>» <br>
                        <span class="two">{{ formSetUp.form_privacy_desc.two }}</span>
                        <span><a href="https://sales.bazis.kz/privacy-policy" target="_blank">{{formSetUp.form_privacy_desc.word }}</a></span>
                        <span class="three">{{ formSetUp.form_privacy_desc.three }}</span>
                    </p>
                </div>
            </div>
        </div>
    </vue-modal>
    <!-- end -->
</template>

<script>
    import {mapGetters} from 'vuex'
    import formFields from '@/components/forms/form-flat.vue';
	import formMail from '@/components/forms/form-mail.vue';	
    import svgIcon from '@/components/partials/SvgIcon.vue';

    export default {
        name: 'sale-CRM-flats-modal',
        props: ['mobileView'],
        components: {
            formFields,
            formMail,
            svgIcon,
        },
        data () {
            return {
                showFlatsCrmModal: false,
				// transitionName: 'pop-out',
                formActive: false,
                resizeCardImg: false,
                project: window.host,
                globalLang: window.GlobalLang, 
            }
        },
        computed: mapGetters({
            salesCardPage: 'loadSalesCardPage',
            formSetUp: 'loadFormSetUp',
            modalData: 'loadSalesLandModalDataArr',
            isMobileGlobal: 'loadisMobileGlobal',
        }),
        methods: {
            getNumbersOfString(str) {
                return str.replace(/[^0-9]+/g, "");
            },
            getNumbersOfBlock(str) { 
                var parseStr = str.split(',')[0];
                return parseStr.replace(/[^0-9]+/g, "");
            },
            getNumber(n) {
                return String(n).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            },
            getFullPrice(price, area) {
                const result = price * area;
                return this.getNumber(parseInt(result));
            },
            getIdPDF(arr) {
                var filItem = arr.filter(item => {
                    return item.typeCode == '5'
                });
                if (filItem.length) {
                    this.pdf = filItem[0].id;
                } else {
                    this.pdf = null;
                }
                // console.log(this.pdf, 'asdasdasdas');
            },
            FlatCardModalClosed() {
				this.formActive = false;
                this.$store.commit('modalData', null);
                document.getElementById('header').classList.remove('notVisible');
                if (!this.isMobileGlobal) {
                    document.getElementById('header-fixed').classList.add('slicky');
                }
			},
			FlatCardModalOpened() {
				// window.body.addClass('overflowTrue');
                // document.getElementById('header').classList.add('notVisible');
			},
            FlatCardModalBeOpened() {
				window.Sharer.init();
                document.getElementById('header').classList.add('notVisible');
                if (!this.isMobileGlobal) {
                    document.getElementById('header-fixed').classList.remove('slicky');
                }
            },
            closeFlatModal() {
                this.$vfm.hide('flats-crm-modal');
            },
            InputMailInit() {
				this.formActive = true;
            },
            resizeModalCardImg() {
				this.resizeCardImg = !this.resizeCardImg;
            },
            CapitalizeID(s) {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1)
            },
            // setGridView(param) { 
			// 	this.$store.commit('gridView', param);
            // },
        },
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/flats-crm-modal.scss';
    @import '@/styles/components/forms/form-flat.scss';
    // RESPOND
    @import '@/styles/components/flats-crm/respond/flats-crm-modal-respond.scss';
</style>