<template>
    <div class="index-promo-section" v-if="indexPromo != null && pageSetup != null">
        <div class="promo-pic-desctop" v-if="!isMobileGlobal">
            <img :src="indexPromo.index_promo_images" alt="">
        </div>
        <div class="promo-pic-desctop-mobile" v-if="isMobileGlobal">
            <img :src="indexPromo.index_promo_mobile" alt="">
        </div>
        <div class="logo-word-mobile" v-if="isMobileGlobal">
            <img alt="lumiere logo word" :src="pageSetup.logo_word">
        </div>
        <div class="page-title">
            <h1 v-html="pageSetup.project_title"></h1>
        </div>
        <div class="promo-hot-btns">
            <ul>
                <li>
                    <a href="#menu-link-filter" v-smooth-scroll="{offset: offset}">
                        <span v-if="!isMobileGlobal">{{pageSetup.btn_flats}}</span>
                        <span v-else>{{ pageSetup.btn_flats_mobile }}</span>
                    </a>
                </li>
                <li><a :href="pageSetup.btn_present_group.file" target="_blank">{{pageSetup.btn_present_group.title}}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'index-promo-section',
        data () {
            return {
                offset: 0,
                isMobile: false,
            }
        },
        computed: mapGetters({
            pageSetup: 'loadPageSetUp',
            indexPromo: 'loadIndexPromo',
            isMobileGlobal: 'loadisMobileGlobal',
        }),
        components: {
            // svgIcon,
            // promoForm,
        },
        
        methods: {
            isMobileCheck() {
                if (window.innerWidth < 767) {
                    this.isMobile = true
                    this.mobileOffset();
                }
            },
            mobileOffset() {
                this.offset = -55
            }
        },
        created() {
            this.isMobileCheck();
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/index-promo/index-promo.scss';
    @import '@/styles/components/index-promo/index-promo-respond.scss';
</style>