<template>
    <div class="location-collection">
        <div class="container">
            <div class="collection-images-container">
                <div class="collection-images" v-if="!isMobileGlobal">
                    <div class="item-img" v-for="(item, index) in locationArr.wow_images" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="collection-images-mobile collection-images-mobile-top" v-if="isMobileGlobal">
                    <div class="item-img" v-for="(item, index) in locationArr.wow_images.slice(0, 3)" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="collection-desc">
                    <div class="section-title-default">
                        <h3 v-html="locationArr.wow_title"></h3>
                        <svgIcon name="arrow-title" />
                    </div>
                    <div class="section-title-desc" v-html="locationArr.wow_desc"></div>
                </div>
                <div class="collection-images-mobile collection-images-mobile-bottom" v-if="isMobileGlobal">
                    <div class="item-img" v-for="(item, index) in locationArr.wow_images.slice(5)" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
            </div>
            <div class="collection-under-img-desc">
                <p v-html="locationArr.wow_images_desc"></p>
            </div>
            <div class="collection-pins-list">
                <div class="pins-bg" :style="'background-image:url(' + premsBg + ')'"></div>
                <div class="pin-item" v-for="(item, index) in locationArr.wow_list_info" :key="index">
                    <strong :class="{'clear' : item.desc == ''}" v-html="item.name"></strong>
                    <span v-if="item.desc != ''" v-html="item.desc"></span>
                </div>
            </div>
        </div>
        
        <!-- <div class="container">
            <div class="location-map-info">
                <div class="section-title-default">
                    <h3 v-html="locationArr.location_title"></h3>
                    <svgIcon name="arrow-title" />
                </div>
                <div class="desc-columns">
                    <div class="col-left">
                        <div class="desc-col"><p v-html="locationArr.location_desc"></p></div>
                    </div>
                    <div class="col-right">
                        <div class="desc-col"><p v-html="locationArr.location_desc_right"></p></div>
                    </div>
                </div>
            </div>
            <div class="location-bg" :style="'background-image:url(' + mapBg + ')'"></div>
        </div> -->
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'location-collection',
        data () {
            return {
                sectionBg: require('@/assets/images/samples/location-bg-03.png'),
                premsBg: require('@/assets/images/samples/prems-bg-01.png'),
            }
        },
        computed: mapGetters({
            locationArr: 'loadLocation',
            isMobileGlobal: 'loadisMobileGlobal',
        }),
        components: {
            svgIcon,
            // promoForm,
        },
        methods: {
            // scrollLeftMapMobile() {
            //     setTimeout(() => {
            //         document.getElementById('scrollContainerMapLocation').scrollLeft += 200;
            //     }, 300);
            // }
        },
        mounted() {
            // this.scrollLeftMapMobile();
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/location/location-collection.scss';
    @import '@/styles/components/location/respond/location-collection-respond.scss';
</style>