<template>
    <div class="concept-arch" id="menu-link-arch">
        <div class="container">
            <div class="concept-arch-info">
                <div class="section-title-default">
                    <h3 v-html="conceptArr.concept_title"></h3>
                    <svgIcon name="arrow-title" />
                </div>
                <div class="desc-columns desc-columns-concept-arch">
                    <div class="col-left">
                        <div class="desc-col"><p v-html="conceptArr.concept_desc"></p></div>
                    </div>
                    <div class="col-right">
                        <div class="desc-col"><p v-html="conceptArr.concept_desc_right"></p></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="concept-promo-pic" :style="'background-image:url(' + conceptArr.concept_img_big + ')'"></div>
        <div class="concept-arch-images-container">
            <div class="container">
                <div class="concept-arch-images" v-if="!isMobileGlobal">
                    <div class="item-img" v-for="(item, index) in conceptArr.concept_images" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="concept-arch-images-mobile concept-arch-images-mobile-top" v-if="isMobileGlobal">
                    <div class="item-img" v-for="(item, index) in conceptArr.concept_images.slice(0, 4)" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="concept-arch-images-desc">
                    <p v-html="conceptArr.concept_images_desc"></p>
                </div>
                <div class="concept-arch-images-mobile concept-arch-images-mobile-bottom" v-if="isMobileGlobal">
                    <div class="item-img" v-for="(item, index) in conceptArr.concept_images.slice(4)" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="concept-pins-list">
            <div class="container">
                <div class="concept-pins-list-in">
                    <!-- <div class="prems-bg" :style="'background-image:url(' + premsBg + ')'"></div> -->
                    <div class="pin-item" v-for="(item, index) in conceptArr.concept_info_list" :key="index">
                        <div class="num">{{ index + 1 }}</div>
                        <strong>{{ item.name }}</strong>
                        <span>{{ item.desc }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import {mapGetters} from 'vuex'
    export default {
        name: 'concept-arch',
        data () {
            return {
            }
        },
        computed: mapGetters({
            conceptArr: 'loadConcept',
            isMobileGlobal: 'loadisMobileGlobal',
        }),
        components: {
            svgIcon,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/concept/concept-arch.scss';
    @import '@/styles/components/concept/respond/concept-arch-respond.scss';
</style>