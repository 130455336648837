<template>
    <div class="inner-section" v-if="innerContentArr != null" id="menu-link-inner">
        <div class="section-title">
            <h2>{{innerContentArr.inner_title_name}}</h2>
        </div>
        <div class="inner-section-content">
            <div class="container">
                <div class="section-title-default">
                    <h3 v-html="innerContentArr.inner_title"></h3>
                    <svgIcon name="arrow-title" />
                </div>
                <div class="desc-columns desc-columns-inner">
                    <div class="desc-col"><p v-html="innerContentArr.inner_desc"></p></div>
                </div>
            </div>
            <div class="inner-promo-pic" :style="'background-image:url(' + innerContentArr.inner_big_img + ')'"></div>
            <div class="container">
                <div class="inner-pins-list">
                    <div class="pins-bg" :style="'background-image:url(' + premsBg + ')'"></div>
                    <div class="pin-item" v-for="(item, index) in innerContentArr.inner_info_list" :key="index">
                        <strong v-html="item.name"></strong>
                        <p v-html="item.desc"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import svgIcon from '@/components/partials/SvgIcon.vue';

    export default {
        name: 'inner-section',
        data () {
            return {
                premsBg: require('@/assets/images/samples/prems-bg-01.png'),
            }
        },
        computed: mapGetters({
            innerContentArr: 'loadInnerContent',
        }),
        components: {
            svgIcon,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/inner-content/inner-section.scss';
    @import '@/styles/components/inner-content/inner-section-respond.scss';
</style>