<template>
  <div
    class="form-group TextInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <input
        class="form-control"
        :name="name"
        :id="name"
        :type="type"
        :value="inputValue"
        :placeholder="placeholder"
        @input="handleChange"
        @blur="handleBlur"
    />

  </div>
</template>

<script>
import { toRef } from "vue";
import { useField } from "vee-validate";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    // label: {
    //   type: String,
    //   required: true,
    // },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const name = toRef(props, "name");
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      initialValue: props.value,
    });
    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
    @import '@/styles/components/forms/form-mail.scss';
    @import '@/styles/components/forms/form-fields-flat.scss';
</style>