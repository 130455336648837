<template>
    <div class="lobbi-section" v-if="lobbiArr != null" id="menu-link-lobbi">
        <div class="section-title">
            <h2>{{lobbiArr.lobbi_title_name}}</h2>
        </div>
        <div class="lobbi-section-content">
            <div class="container">
                <div class="desc-columns desc-columns-lobbi">
                    <div class="col-left">
                        <div class="desc-col"><p v-html="lobbiArr.lobbi_desc"></p></div>
                    </div>
                    <div class="col-right">
                        <div class="desc-col"><p v-html="lobbiArr.lobbi_desc_right"></p></div>
                    </div>
                </div>
                <div class="lobbi-images" v-if="!isMobileGlobal">
                    <div class="item-img" v-for="(item, index) in lobbiArr.lobbi_gallery" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                 
                <div class="panarams-gallery" v-if="isMobileGlobal">
                    <!-- :navigation="swiperOptions.navigation" -->
                    <swiper 
                        :modules="modules" 
                        effect="fade"
                        :slides-per-view="1"
                        :space-between="0"
                        :observer="true"
                        :observe-parents="true"
                        :loop="true"
                    >
                        <swiper-slide v-for="(item, index) in lobbiArr.lobbi_gallery_mobile" :key="index">
                            <div class="slide-pic" :style="'background-image:url(' + item + ')'"></div>
                        </swiper-slide>
                    </swiper>
                </div>


                <div class="lobbi-pins-list">
                    <div class="pins-bg" :style="'background-image:url(' + premsBg + ')'"></div>
                    <div class="pin-item" v-for="(item, index) in lobbiArr.lobbi_info_list" :key="index">
                        <strong v-html="item.name"></strong>
                        <span v-html="item.desc"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { EffectFade, Autoplay, Navigation} from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	// Import Swiper styles
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';
    export default {
        name: 'lobbi-section',
        data () {
            return {
                premsBg: require('@/assets/images/samples/prems-bg-01.png'),
                swiperOptions: {
                    navigation: {
                        nextEl: '#panaramsNext',
                        prevEl: '#panaramsPref'
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: false
                    },
                },
            }
        },
        computed: mapGetters({
            lobbiArr: 'loadLobbiArr',
            isMobileGlobal: 'loadisMobileGlobal',
        }),
        components: {
            Swiper,
			SwiperSlide,
        },
        setup() {
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation],
			};
		},
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/lobbi/lobbi-section.scss';
    @import '@/styles/components/lobbi/lobbi-section-respond.scss';
</style>