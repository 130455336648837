<template>
    <div class="flat-mail-input" id="flat-mail-input" v-if="formSetUp != null">
        <Form class="form-in" v-if="formVisible" :validation-schema="schema" v-slot="{ errors }" @submit="onSubmit">
            <TextInput
                name="email"
                type="email"
                placeholder="E-mail"
                class="form-group-email"
                :class="{'error' : errors.email}"
            />
            <div class="btn-send">
                <button class="btn-submit btn-success" type="submit" :disabled="errors.email">
                    <svgIcon name="plane" class="icon-plane"/>
                </button>
            </div>
        </Form>
        <div class="successMessage" v-else>
            <h3>отправлено</h3>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { Form } from "vee-validate";
    import * as Yup from "yup";
    import { ref } from 'vue'
    import TextInput from "@/components/forms/TextInput.vue";
    import svgIcon from '@/components/partials/SvgIcon.vue';

    export default {
        name: 'formMail',
        props: ['options'],
        data () {
            return {
                formVisible: true,
                lang: window.GlobalLang,
            }
        },
        components: {
            TextInput,
            Form,
            svgIcon,
        },
        computed: mapGetters({
            formSetUp: 'loadFormSetUp',
        }),
        setup() {
            const schema = Yup.object().shape({
                email: Yup.string()
                    .required('Email is required')
                    .matches(/[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+/)
            });
            return {
                schema,
            };
        },
        methods: {
            onSubmit(values) {
                this.formVisible = false;
                var querystring = require('querystring');
                var data = {
                    'pdf': this.options[0].pdf,
                    'name': this.options[0].name,
                    'mail': values.email,
                    'nameHtml': 'send_flat.html'
                };
                console.log('ASDASDASDASDAS', data);
                axios.post('/formFlat.php',querystring.stringify(data));
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/components/forms/form-mail.scss';
</style>