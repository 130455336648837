<template>
    <div class="flats-section-content">
        <div class="section-arr">
            <div class="section-images">
                <div class="item-img" v-for="(item, index) in apartmentsArr.flats_images_first" :key="index"><img :src="item.img" alt=""></div>
            </div>
            <div class="container">
                <div class="section-pins-list">
                    <div class="pins-bg" :style="'background-image:url(' + flatsBg + ')'"></div>
                    <div class="pin-item" v-for="(item, index) in apartmentsArr.flats_list_info_first" :key="index">
                        <strong v-html="item.desc"></strong>
                        <span v-html="item.name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-arr">
            <div class="container">
                <div class="section-title-default section-title-white">
                    <h3 v-html="apartmentsArr.flats_title_second"></h3>
                    <svgIcon name="arrow-title" />
                </div>
                <div class="desc-columns desc-columns-flats-section-arr">
                    <div class="desc-col"><p v-html="apartmentsArr.flats_title_desc_second"></p></div>
                </div>
            </div>
            <div class="section-images">
                <div class="item-img" v-for="(item, index) in apartmentsArr.flats_images_second" :key="index"><img :src="item.img" alt=""></div>
            </div>
            <div class="container">
                <div class="section-pins-list">
                    <div class="pins-bg" :style="'background-image:url(' + flatsBg_01 + ')'"></div>
                    <div class="pin-item" v-for="(item, index) in apartmentsArr.flats_list_info_second" :key="index">
                        <strong v-html="item.desc"></strong>
                        <span v-html="item.name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-arr">
            <div class="container">
                <div class="section-title-default section-title-white">
                    <h3 v-html="apartmentsArr.flats_title_three"></h3>
                    <svgIcon name="arrow-title" />
                </div>
                <div class="desc-columns desc-columns-flats-section-arr-full">
                    <div class="desc-col"><p v-html="apartmentsArr.flats_title_desc_three"></p></div>
                </div>
            </div>
            <div class="section-images">
                <div class="item-img" v-for="(item, index) in apartmentsArr.flats_images_third" :key="index"><img :src="item.img" alt=""></div>
            </div>
            <div class="container">
                <div class="section-pins-list">
                    <div class="pins-bg" :style="'background-image:url(' + flatsBg_02 + ')'"></div>
                    <div class="pin-item" v-for="(item, index) in apartmentsArr.flats_list_info_third" :key="index">
                        <strong v-html="item.desc"></strong>
                        <span v-html="item.name"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'flats-section-content',
        data () {
            return {
                flatsBg: require('@/assets/images/samples/flats-section-bg-01.png'),
                flatsBg_01: require('@/assets/images/samples/flats-section-bg-02.png'),
                flatsBg_02: require('@/assets/images/samples/flats-section-bg-03.png'),
            }
        },
        computed: mapGetters({
            apartmentsArr: 'loadApartments',
            isMobileG: 'loadisMobileGlobal',
        }),
        components: {
            svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats/flats-section-content.scss';
    @import '@/styles/components/flats/respond/flats-section-content-respond.scss';
</style>