<template>
    <div class="flats-crm-filter-mobile" :class="{'open' : filterVisible}">
        <div class="top-header">
            <a href="#" class="link-back" @click.prevent="closeFilterMobile">
                <svgIcon name="left-arrow-crm" class="icon-left-arrow-crm"/>
                <span>Назад</span>
            </a>
            <h3>ФИЛЬТР</h3>
            <a href="#" class="link-reset" @click.prevent="resetFilterCrm">
                Сбросить
            </a>
        </div>
        <div class="flats-filtter-container">
            <flatsFilter></flatsFilter>
        </div>
    </div>   
</template>

<script>
    import {mapGetters} from 'vuex'
	import flatsFilter from '@/components/flats-crm/flats-crm-filter.vue'
    import svgIcon from '@/components/partials/SvgIcon.vue';

    export default {
        name: 'sale-CRM-flats-filter-mobile',
        components: {
            flatsFilter,
            svgIcon,
        },
        data () {
            return {
                filterData: null,
                count: 0,
            }
        },
        computed: mapGetters({
            setup: 'loadPageSetUp',
            filterVisible: 'loadVisibleFilterMobile',
            paginPageNumber: 'loadPaginPageNumber',
            resetModeTrigger: 'loadResetTriggerMove',
        }),
        methods: {
            closeFilterMobile() {
                window.body.removeClass('overflowTrue');
                document.getElementById('header').classList.remove('notVisible');
				this.$store.commit('SetVisibleFilterMobile', false);
            },
            resetFilterCrm() {
                this.count = this.count + 1;
                this.filterData = {
                    complexCodes: [this.setup.crm_code],
                    roomQuantities: [],
                    areaFrom: this.setup.filter_set_size.min,
                    areaTo: this.setup.filter_set_size.max,
                    floorRangeStart: this.setup.filter_set_floor.min,
                    floorRangeEnd: this.setup.filter_set_floor.max,
                    meterPriceFrom: this.setup.filter_set_money.min,
                    meterPriceTo: this.setup.filter_set_money.max,
                    linesOnPage: 3,
                    pageNumber: 1,
                };
                this.$store.commit('UpdatePaginPageNumber', 1);
                this.$store.commit('setFlatsFilterData', this.filterData);
                this.$store.dispatch('getFlatsByFilter', this.filterData);
                this.$store.dispatch('getFlatsPagintationCounters', this.filterData);
                this.closeFilterMobile();
                this.$store.commit('TriggerResetFilterMove', this.count);
            },
        },
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        }
    }
</script>

<style lang="scss">
    // @import '@/styles/components/flats-crm/flats-crm-filter.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-filter-mobile.scss';
</style>