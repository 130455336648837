<template>
    <div class="concept-safe" id="menu-link-safe">
        <div class="container">

            <div class="column-container">
                <div class="col-left">
                    <div class="concept-column-info">
                        <div class="title">
                            <h3 v-html="conceptArr.safe_title"></h3>
                            <svgIcon name="arrow-title" />
                        </div>
                        <div class="desc-html" v-html="conceptArr.safe_desc"></div>
                    </div>
                    <div class="concept-column-info-images-mobile" v-if="isMobileGlobal">
                        <div class="item-img" v-for="(item, index) in conceptArr.safe_images" :key="index">
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                    <div class="concept-column-info-list">
                        <ul>
                            <li v-for="(item, index) in conceptArr.safe_list_info" :key="index">
                                <p v-html="item.desc"></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-right" v-if="!isMobileGlobal">
                    <div class="concept-column-info-images">
                        <div class="item-img" v-for="(item, index) in conceptArr.safe_images" :key="index">
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'concept-safe',
        data () {
            return {
            }
        },
        computed: mapGetters({
            conceptArr: 'loadConcept',
            isMobileGlobal: 'loadisMobileGlobal',
        }),
        components: {
            svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/concept/concept-safe.scss';
    @import '@/styles/components/concept/respond/concept-safe-respond.scss';
</style>