<template>
    <div class="burger-manu" v-if="pageSetup != null" :class="{'open' : openSendwich, 'slicky' : slicky}">
        <div class="container">
            <div class="lang-mobile">
                <ul>
                    <li :class="{'active' : globalLang == 'kz/'}"><a href="#" @click.prevent="setLocale('kz/')">Каз</a></li>
                    <li :class="{'active' : globalLang == ''}"><a href="#" @click.prevent="setLocale('')">Рус</a></li>
                </ul>
            </div>
            <navMain></navMain>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import navMain from '@/components/nav-main/nav-main.vue'
    export default {
        name: 'location-section',
        data () {
            return {
                globalLang: window.GlobalLang, 
                slicky: false,
            }
        },
        computed: mapGetters({
            openSendwich: 'loadOpenSendwich',
            pageSetup: 'loadPageSetUp',
        }),
        components: {
            navMain,
        },
        methods: {
            setLocale(lang) {
                localStorage.setItem('languageTheOneSite', lang);
                document.location.reload(true);
            },
            slickyHeader() {
                if (window.scrollY >= window.innerHeight + 300) {
                    this.slicky = true;
                } else {
                    this.slicky = false;
                }
            },
        },
        created () {
            window.addEventListener('scroll', this.slickyHeader);
        },
        unmounted() {
            window.removeEventListener('scroll', this.slickyHeader);
        },
        mounted () {
            this.$store.watch(
                (state) => state.pageSetup,
                (newValue) => {
                    if (newValue)
                    this.slickyHeader();
                }
            )
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/menu/burger-menu.scss';
</style>