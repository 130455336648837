<template>
    <div class="prems-section" v-if="premsArr != null" id="menu-link-prems">
        <div class="container">
            <div class="prems-pins-list">
                <div class="prems-bg" :style="'background-image:url(' + premsBg + ')'"></div>
                <div class="pin-item" v-for="(item, index) in premsArr.prems_list" :key="index">
                    <strong>{{ item.info }}</strong>
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'prems-section',
        data () {
            return {
                premsBg: require('@/assets/images/samples/prems-bg-01.png'),
            }
        },
        computed: mapGetters({
            premsArr: 'loadPremsArr',
        }),
        components: {
        },
        methods: {
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/prems/prems-section.scss';
    @import '@/styles/components/prems/prems-section-respond.scss';
    // @import '@/styles/components/index-promo/index-promo-respond.scss';
</style>